import Avatar from 'components/Avatar';
import { MA_ID, PB_ID } from 'constant';
import React from 'react';

const getName = (notify) => {
  const {
    fromRoleId,
    fromUserFirstName,
    fromUserLastName,
    maLegalName,
    maOperatingName,
    pbLegalName,
    pbOperatingName
  } = notify;
  if (fromRoleId === MA_ID) {
    if (maOperatingName) {
      return maOperatingName;
    } else if (maLegalName) {
      return maLegalName;
    }
  } else if (fromRoleId === PB_ID) {
    if (pbOperatingName) {
      return pbOperatingName;
    } else if (pbLegalName) {
      return pbLegalName;
    }
  }

  return `${fromUserFirstName} ${fromUserLastName}`;
};

const getNotification = (notify) => {
  const updatedName = getName(notify);
  return (
    <>
      <div>
        <Avatar size={45} image={notify.fromUserProfilePicture} fname={updatedName} />
      </div>
      <span className="text-message">
        <b>{updatedName}</b>
        <span dangerouslySetInnerHTML={{ __html: notify?.message }}></span>
      </span>
    </>
  );
};

export { getNotification };
