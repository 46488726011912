import React, { useState } from 'react';
import { Button, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';
import faEyeSlash from 'assets/icons/eye.svg';
import faEye from 'assets/icons/eye-open.svg';
import { BsCheck2 } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';

const FormikInputField = ({
  field: { ...fields },
  form: { touched, errors, setFieldValue, handleBlur, values },
  onChange,
  ...props
}) => {
  const {
    label,
    id,
    isRequiredLabel,
    isrequiredlabel,
    isoptionallabel,
    grouptextcurrency,
    grouptextpercentage,
    grouptexttoggle,
    password,
    groupcanadacurrency,
    isError = true,
    textAreaheight,
    isverified,
    optionallabel,
    groupyear,
    groupmonth,
    isVerificationEnabled,
    isString = false,
    isCorrectValue,
    openCloseVerifyModal,
    isVerifiedValue,
    ...rest
  } = props;
  const { name } = fields;
  const [passwordIcon, setPasswordIcon] = useState(true);

  const getValue = (val) => {
    if ((grouptextcurrency || groupcanadacurrency) && val) {
      return isString ? `${parseFloat(val.replace(/,/g, ''))}` : parseFloat(val.replace(/,/g, ''));
    } else {
      return val;
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (onChange) {
      onChange(name, getValue(value));
    }
    setFieldValue(name, getValue(value), true);
  };

  const togglePasswordIcon = () => {
    setPasswordIcon(!passwordIcon);
  };

  const showGroupText = () => {
    if (password) {
      return (
        <InputGroupText className="cursor-pointer" onClick={togglePasswordIcon}>
          <Button color="link" className="p-0 password-eye" type="button">
            {passwordIcon ? (
              <img src={faEyeSlash} alt="" width={18} />
            ) : (
              <img src={faEye} alt="" width={18} />
            )}
          </Button>
        </InputGroupText>
      );
    }
    if (grouptextcurrency) {
      return (
        <InputGroupText>
          <span className="font-size-14">$</span>
        </InputGroupText>
      );
    }
    if (groupcanadacurrency) {
      return (
        <InputGroupText>
          <span className="font-size-14">CAD $</span>
        </InputGroupText>
      );
    }
    if (grouptextpercentage) {
      return (
        <InputGroupText>
          <span className="font-size-14">%</span>
        </InputGroupText>
      );
    }
    if (grouptexttoggle) {
      return (
        <select name="" id="" className="input-field-select">
          <option>%</option>
          <option>$</option>
        </select>
      );
    }
    if (groupyear) {
      return (
        <InputGroupText>
          <span className="font-size-14">yr(s)</span>
        </InputGroupText>
      );
    }
    if (groupmonth) {
      return (
        <InputGroupText>
          <span className="font-size-14">mo(s)</span>
        </InputGroupText>
      );
    }

    return null;
  };

  const renderInput = () => {
    if (grouptextcurrency || groupcanadacurrency) {
      const { type, ...restVal } = rest;
      const { size } = props;
      return (
        <NumericFormat
          id={id}
          allowLeadingZeros
          thousandSeparator=","
          onBlur={handleBlur}
          {...fields}
          {...restVal}
          onChange={handleChange}
          style={{ height: textAreaheight ? `${textAreaheight}px` : '' }}
          className={`form-control form-control-${size} ${
            touched[name] && errors[name] ? 'is-invalid' : ''
          }`}
        />
      );
    }
    const { size: fSize, bsSize: fBsSize, ...restFields } = fields;
    const { size: pSize, bsSize: pBsSize, ...restProps } = props;
    const { size: rSize, bsSize: rBsSize, ...restValue } = rest;
    return (
      <Input
        type={passwordIcon ? 'password' : null}
        invalid={Boolean(touched[name] && errors[name])}
        onBlur={handleBlur}
        id={id}
        style={{ height: textAreaheight ? `${textAreaheight}px` : '' }}
        {...restProps}
        {...restFields}
        {...restValue}
        bsSize={fSize || fBsSize || pSize || pBsSize || rSize || rBsSize}
        onChange={handleChange}
      />
    );
  };

  return (
    <>
      {label && (
        <Label for={id} className="label-color w-100">
          <div className="d-flex justify-content-between align-items-center">
            {label}
            {isRequiredLabel || isrequiredlabel ? (
              <span className="text-muted font-size-14">Required</span>
            ) : null}
            {isoptionallabel || optionallabel ? (
              <span className="text-muted font-size-14">{optionallabel || 'Optional'}</span>
            ) : null}
          </div>
        </Label>
      )}

      <div className="position-relative">
        <InputGroup>
          {renderInput()}
          {showGroupText()}
        </InputGroup>
        {isVerifiedValue && (
          <BsCheck2
            color="#0099FF"
            size={20}
            className="position-absolute"
            style={{ top: '13px', right: '10px' }}
          />
        )}
        {isCorrectValue && isVerificationEnabled && name === 'email' && (
          <Button
            color="link"
            type="button"
            onClick={openCloseVerifyModal}
            className="position-absolute verify-btn">
            Verify
          </Button>
        )}
      </div>

      {isError && (
        <ErrorMessage
          name={name}
          render={(msg) => {
            return <div className="error-message">{msg}</div>;
          }}
        />
      )}
    </>
  );
};
export default FormikInputField;
