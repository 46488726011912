import React, { useContext, useState, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settingIcon from 'assets/logos/settings.svg';
import profileIcon from 'assets/logos/person.svg';
import logoutIcon from 'assets/logos/power.svg';
import Avatar from 'components/Avatar';
import { getUser, onLogOut } from 'utils/UserDetails';
import { RoleContext } from 'context/Role';
import {
  APPROVED_KEY,
  BROKER_ROLE_KEY,
  INVESTOR_ROLE_KEY,
  MA_ROLE_KEY,
  PB_ROLE_KEY,
  SUPER_ADMIN_ID
} from 'constant';

const NavMenu = ({ userDetails }) => {
  const navigate = useNavigate();
  const user = getUser();
  const onHandleLogOut = () => {
    onLogOut();
    navigate('/login');
  };

  const { roleKey } = useContext(RoleContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const onHandleClick = () => {
    switch (roleKey) {
      case MA_ROLE_KEY:
        return navigate('/profile/personal-information');
      case INVESTOR_ROLE_KEY:
        return navigate('/profile/personal-info');
      case PB_ROLE_KEY:
        return navigate('/profile/personal-details');
      case BROKER_ROLE_KEY:
        return navigate('/profile/personal-details');
      default:
        break;
    }
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="profile-toggle" color="link">
          <Avatar
            image={userDetails.profilePicture}
            fname={userDetails.preferredName}
            fontsize={14}
          />
        </DropdownToggle>
        <DropdownMenu className="user-profile border-0">
          <DropdownItem header>
            <div className="mt-2 mb-4 d-flex justify-content-center">
              <Avatar
                image={userDetails.profilePicture}
                fname={userDetails.preferredName}
                size={80}
              />
            </div>

            <p
              className="mb-2 text-dark font-size-16 user-details-drop"
              title={userDetails?.preferredName}>{`${userDetails?.preferredName}`}</p>
            <p className="mb-2 user-details-drop" title={user?.email}>
              {user?.email}
            </p>
          </DropdownItem>
          {userDetails?.approvedStatus === APPROVED_KEY && userDetails.roleId !== SUPER_ADMIN_ID ? (
            <DropdownItem onClick={onHandleClick}>
              {/* <Link
                to="/profile"
                className="text-dark"
                state={{
                  profile: true,
                  title: roleTitle
                }}> */}
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <img
                    src={profileIcon}
                    alt="profile icon"
                    className="me-3 profile-icon"
                    height={20}
                  />
                  Profile
                </div>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
              {/* </Link> */}
            </DropdownItem>
          ) : null}

          <DropdownItem>
            <Link to="/settings" className="text-dark">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <img
                    src={settingIcon}
                    alt="setting-icon"
                    className="me-3 profile-icon"
                    height={20}
                  />
                  Settings
                </div>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          </DropdownItem>
          <DropdownItem onClick={onHandleLogOut}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img
                  src={logoutIcon}
                  alt="setting-icon"
                  className="me-3 profile-icon"
                  height={20}
                />
                Logout
              </div>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
export default memo(NavMenu);
